/* Estilos do Full Banner */
.full-banner {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  /* Banner Desktop */
  .full-banner-desktop {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .full-banner-mobile {
    display: none; /* Esconde o banner mobile por padrão */
    width: 100%;
    height: auto;
  }
  
  /* Responsividade: Exibir o banner mobile no lugar do desktop */
  @media (max-width: 768px) {
    .full-banner-desktop {
      display: none; /* Esconde o banner desktop no mobile */
    }
  
    .full-banner-mobile {
      display: block; /* Exibe o banner mobile no mobile */
    }
  }
  