

/* Estilos Globais */
body {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }

  /* Estilos para todos os títulos */
h1, h2, h3, h4, h5, h6 {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 800; /* Peso definido */
    color: rgb(0, 0, 0); /* Cor preta */
    line-height: 62px; /* Altura da linha */
  }
  
  h1 {
    font-size: 70px; /* Tamanho especificado */
    line-height: 62px;
    text-transform: uppercase;

  }
  
  h2 {
    font-size: 60px;
  }
  
  h3 {
    font-size: 50px;
  }
  
  h4 {
    font-size: 40px;
  }
  
  h5 {
    font-size: 30px;
  }
  
  h6 {
    font-size: 20px;
  }
  
  