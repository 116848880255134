/* Container Geral */
.contato-container {
    padding: 77px 15px;
    text-align: center;
  }
  
  /* Título da Página */
  .contato-title {
    font-family: "Barlow Condensed", Sans-serif;
    font-size: 70px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 62px;
    margin-bottom: 40px;
    color: #000000; /* Preto */
  }
  
  /* Conteúdo da Página */
  .contato-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1140px;
    margin: 0 auto;
    gap: 20px;
  }
  
  /* Formulário de Contato */
.contato-form {
    flex: 2; /* Aumenta a largura do formulário */
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group input,
  .form-group textarea {
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    padding: 10px;
    border: 2px solid #000000;
    border-radius: 5px;
    width: 100%; /* Garante que os campos ocupem toda a largura do formulário */
    box-sizing: border-box;
  }

  .form-group label {
    font-family: "Barlow Condensed", Sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left; /* Garante o alinhamento à esquerda */
    width: 100%; /* Ocupa toda a largura */
  }
  
  /* Card com Dados de Contato */
  .contato-card {
    flex: 1; /* Reduz a largura do card de contato */
    border: 2px solid #000000;
    background-color: #edd210; /* Amarelo */
    color: #000000;
    padding: 20px;
    border-radius: 10px;
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Ajustes de Responsividade */
  @media (max-width: 768px) {
    .contato-content {
      flex-direction: column;
    }
  
    .contato-form,
    .contato-card {
      width: 100%; /* Ambos ocupam 100% no mobile */
    }
  }
  
  /* Botão do Formulário */
  .contato-button {
    background-color: #000000; /* Preto */
    color: #ffffff; /* Branco */
    font-family: "Barlow Condensed", Sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
  }
  
  .contato-button:hover {
    background-color: #333333; /* Cinza escuro */
  }
  
  /* Card com Dados de Contato */
  .contato-card {
    flex: 1;
    border: 2px solid #000000;
    background-color: #edd210; /* Amarelo */
    color: #000000;
    padding: 20px;
    border-radius: 10px;
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contato-card p {
    margin: 10px 0;
    font-weight: bold;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .contato-content {
      flex-direction: column;
    }
  
    .contato-form,
    .contato-card {
      width: 100%;
    }
  }

  .form-status {
    margin-top: 10px;
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    color: green;
  }

  /* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Popup */
.popup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Popup Button */
.popup-button {
  background-color: #edd210;
  color: #000000;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}

.popup-button:hover {
  background-color: #d4b10e;
}

  
  