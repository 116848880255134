/* Geral da Seção */
.quem-somos {
  padding: 60px 15px;
  background-color: #f1f1f1;
}

/* Container da Seção */
.quem-somos-container {
  display: flex;
  flex-wrap: wrap; /* Garante responsividade */
  align-items: center;
  gap: 20px;
  max-width: 1140px;
  margin: 0 auto;
}

/* Coluna de Imagem */
.quem-somos-image {
  flex: 2;
  max-width: 500px;
}

.quem-somos-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Coluna de Conteúdo */
.quem-somos-content {
  flex: 2;
}

.section-title {
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 62px;
  margin-bottom: 40px;
  color: #000000;
}

/* Texto */
.quem-somos-text {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  color: #000000;
}

/* Responsividade */
@media (max-width: 768px) {
  .quem-somos-container {
    flex-direction: column;
    text-align: center; /* Centraliza o texto no mobile */
  }

  .quem-somos-image {
    max-width: 100%;
  }

  .quem-somos-content {
    flex: unset;
  }
}
