/* Estilos da Seção de Clientes */
.clientes-section {
  padding: 60px 15px;
  text-align: center;
}

.clientes-title {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 70px;
  font-weight: 800;
  color: rgb(0, 0, 0);
  margin-bottom: 40px;
  text-transform: uppercase;
}

/* Grid para Desktop */
@media (min-width: 768px) {
  .clientes-grid {
      display: grid; /* Ativar grid no desktop */
      grid-template-columns: repeat(3, 1fr); /* 3 cards por linha */
      gap: 20px; /* Espaçamento entre os cards */
      justify-items: center;
  }

  .cliente-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px; /* Largura fixa */
      height: 150px; /* Altura fixa */
      background-color: #ffffff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
      text-align: center;
      padding: 10px;
  }

  .cliente-image {
      width: 100%; /* Garante que a imagem ocupe a largura total */
      height: auto; /* Mantém proporção */
      max-height: 120px; /* Limite de altura para imagens */
      object-fit: contain; /* Adapta a imagem ao espaço disponível */
  }
}

/* Banner Mobile */
.clientes-banner-desktop {
  display: block;
  width: 100%;
  height: auto;
}

.clientes-banner-mobile {
  display: none; /* Esconde o banner mobile por padrão */
  width: 100%;
  height: auto;
}

/* Responsividade: Mostrar o banner correto no dispositivo */
@media (max-width: 768px) {
  .clientes-banner-desktop {
      display: none; /* Esconde o banner desktop no mobile */
  }

  .clientes-banner-mobile {
      display: block; /* Exibe o banner mobile no mobile */
  }

  .clientes-grid {
      display: none; /* Ocultar grid no mobile */
  }
}
