/* Geral */
.footer {
    background-color: #edd210; /* Amarelo */
    color: #000000; /* Preto */
    padding: 40px 15px;
}
  
.footer-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 colunas no desktop */
    gap: 20px;
}
  
.footer-column {
    display: flex;
    flex-direction: column;
}
  
.footer-logo {
    width: 236px;
    height: auto;
    margin-bottom: 20px;
}
  
/* Títulos */
.footer-title {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 15px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex; /* Alinha o texto e o ícone */
    justify-content: space-between; /* Espaçamento entre título e ícone */
}

@media (max-width: 768px) {
    .footer-title {
        line-height: 0%;}
}

/* Ícone de colapso (+ e -) */
.toggle-icon {
    display: none; /* Esconde no desktop */
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .toggle-icon {
        display: inline-block; /* Exibe no mobile */
    }
}
  
/* Links e Serviços */
.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links div {
    margin-bottom: 10px; /* Espaço entre os itens */
}

.footer-links a {
    color: #000000;
    text-decoration: none;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* Redes Sociais */
.footer-social-icons {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.footer-social-icons a {
    color: #000000;
    font-size: 24px;
    transition: color 0.3s ease-in-out;
}

.footer-social-icons a:hover {
    color: #333333;
}

.footer-contact p {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 14px;
    margin: 5px 0;
}

/* Conteúdo do colapso */
.footer-content {
    display: none; /* Esconde conteúdo inicialmente */
}

.footer-content.show {
    display: block; /* Exibe conteúdo ao expandir */
}

/* Mobile Layout */
@media (max-width: 768px) {
    .footer-grid {
        grid-template-columns: 1fr; /* 1 coluna no mobile */
    }
  
    .footer-column {
        margin-bottom: 20px;
    }
}

/* Desktop: Sempre visível */
@media (min-width: 769px) {
    .footer-content {
        display: block !important; /* Sempre visível no desktop */
    }
    .footer-services-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 colunas para serviços */
    }
}

.footer-bottom {
    background-color: #000000; /* Preto */
    color: #ffffff; /* Branco */
    text-align: center;
    padding: 10px 0;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 14px;
  }
  
