@font-face {
    font-family: "Heavitas";
    src: url("../assets/fonts/RedHatDisplay-VariableFont_wght.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}


/* Container Geral */
.descoberta-container {
    padding: 77px 15px;
    text-align: center;
    
  }
  
  /* Título da Página */
  .descoberta-title {
    font-family: "Barlow Condensed", Sans-serif;
    font-size: 70px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 62px;
    margin-bottom: 40px;
    color: #000000; /* Preto */
  }
  
  /* Card de Conteúdo */
  .descoberta-card {
    border: 2px solid #000000; /* Borda preta */
    background-color: #edd210; /* Amarelo */
    color: #000000; /* Preto */
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Centraliza o card */
    border-radius: 10px;
    text-align: left;
    font-family: "Barlow Condensed", Sans-serif;
  }
  
  /* Subtítulos no Card */
  .descoberta-subtitle {
    font-weight: bold;
    margin-top: 26px;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  /* Texto do Card */
  .descoberta-card p {
    font-family: "Red Hat Display", sans-serif; /* Nova fonte */
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
  
  /* Botão no Card */
  .descoberta-button {
    background-color: #000000; /* Preto */
    color: #ffffff; /* Branco */
    text-transform: uppercase;
    font-family: "Barlow Condensed", Sans-serif;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .descoberta-button:hover {
    background-color: #333333; /* Cinza escuro */
  }
  