/* Importando a fonte Heavitas */
@font-face {
  font-family: "Heavitas";
  src: url("../assets/fonts/RedHatDisplay-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Aplicando a fonte no navbar */
.navbar-nav .nav-link {
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

/* Header Container */
.header-container {
  position: sticky; /* Mantém o header fixo sem interferir no layout */
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: black; /* Fundo do header */
}

/* Custom Container for Navbar */
.custom-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px; /* Garantir espaçamento nas laterais */
  display: flex;
  justify-content: space-between; /* Alinha os itens entre as extremidades */
  align-items: center;
}

/* Ajuste no mobile para usar apenas container */
@media (max-width: 768px) {
  .custom-container {
      padding: 0 15px;
      max-width: 100%;
  }
}

/* Logo Styles */
.logo {
  height: 40px;
  max-width: 100%;
}

/* Botão do menu no mobile */
.navbar-toggler {
  display: flex;
  align-items: center;
  margin-left: auto; /* Garante que o botão do menu vá para a direita */
}

/* Navbar */
.navbar-nav {
  display: flex;
  align-items: center;
  gap: 45px; /* Espaçamento entre os links */
 
}

@media (max-width: 769px) {
  .navbar-nav {
   margin-bottom: 15px;
  }
}

.navbar-collapse {
  flex-grow: unset !important; /* Impede o menu de ocupar espaço desnecessário */
}

/* Navbar Links */
.nav-link {
  color: white;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
  padding: 5px 10px;
}

.nav-link:hover {
  color: #edd210;
}

/* Highlight Button */
.btn-highlight {
  color: black !important;
  background-color: #edd210;
  padding: 5px 15px;
  border-radius: 8px;
  font-weight: bold;
  text-decoration: none;
}

.btn-highlight:hover {
  background-color: #d4b10e;
  color: black !important;
}

/* Ajustes para Mobile */
@media (max-width: 768px) {
  /* Garantir que o header permaneça fixo no mobile */
  .header-container {
      position: fixed; /* Corrige o header no topo no mobile */
      padding: 0 15px; /* Ajusta espaçamento lateral */
  }

  /* Custom Container no Mobile */
  .custom-container {
      display: flex;
      justify-content: space-between; /* Alinha logo à esquerda e ícone à direita */
      align-items: center;
  }

  /* Logo */
  .logo {
      margin-left: 0; /* Remove margens adicionais no mobile */
  }

  /* Botão do Menu */
  .navbar-toggler {
      margin-right: 0; /* Garante alinhamento à direita */
  }

  /* Menu abre logo abaixo do header */
  .navbar-collapse {
      position: absolute;
      top: 100%; /* Abre o menu logo abaixo do header */
      left: 0;
      width: 100%; /* Ocupa toda a largura */
      background-color: black; /* Mesmo fundo do header */
      z-index: 999;
  }

  /* Links em coluna no menu no mobile */
  .navbar-collapse.show {
      display: flex !important;
      flex-direction: column; /* Exibe links em coluna */
  }

  /* Links do Menu */
  .navbar-nav .nav-link {
      text-align: left; /* Alinha os links à esquerda */
      padding: 10px 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Linha separadora */
  }

  .navbar-nav .nav-link:last-child {
      border-bottom: none; /* Remove linha separadora do último link */
  }

  /* Ajuste do corpo para não sobrepor o conteúdo principal */
  body {
      padding-top: 56px; /* Altura do header no mobile */
  }
}

/* Desktop: Sem impacto */
@media (min-width: 769px) {
  .navbar-collapse {
      position: relative; /* Garante que o menu do desktop não seja afetado */
      top: unset;
      left: unset;
      width: auto; /* Mantém o layout do desktop */
  }
}
