/* Seção Nossos Serviços */
.servicos-section {
    padding: 60px 15px;
    background-color: #f1f1f1;
    text-align: center;
}

.section-title {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 70px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    margin-bottom: 40px;
    text-transform: uppercase;
}

/* Grid de Cards (Desktop) */
.servicos-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
}

@media (max-width: 768px) {
    .servicos-grid {
        display: none;
    }
}

/* Carousel para Mobile */
.carousel {
    display: none;
}

@media (max-width: 768px) {
    .carousel {
        display: block;
    }
}

/* Estilo de cada Card */
.servico-card {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    min-height: 256px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

/* Texto sobre a Imagem */
.servico-title-container {
    /* background-color: rgba(0, 0, 0, 0.7); */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: auto;
}

.servico-title {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 44px;
    font-weight: 800;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    line-height: 45px;
}

/* Botão Saiba Mais */
.saiba-mais-btn {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    background-color: #edd210;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}

.saiba-mais-btn:hover {
    background-color: #d4b10e;
}

/* Responsividade */
@media (max-width: 768px) {
    .carousel-item .servico-card {
        min-width: 100%;
    }
}
