/* Seção Plataformas */
.plataformas-section {
    padding: 60px 15px;
    background-color: #f9f9f9;
    text-align: center;
}

.section-title {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 54px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    margin-bottom: 40px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .section-title {
        font-size: 50px;
    }
}


/* Grid para Desktop */
.plataformas-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
}

@media (max-width: 768px) {
    .plataformas-grid {
        display: none; /* Ocultar grid no mobile */
    }
}

/* Card */
.plataforma-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    max-width: 332px;
    height: 255px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 auto;
}

.plataforma-image {
    width: 100%;
    height: 227px;
    object-fit: contain;
    margin-bottom: 10px;
}

.plataforma-description {
    font-family: "Red Hat Display", sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: #000000;
}

/* Carousel para Mobile */
/* Carousel para Mobile */
@media (max-width: 768px) {
    .carousel {
      display: block; /* Exibir apenas no mobile */
      width: 100%;
    }
  
    .carousel-inner {
      position: relative;
      overflow: hidden; /* Garante que apenas o item ativo seja visível */
      display: flex;
      flex-wrap: nowrap;
    }
  
    .carousel-item {
      flex: 0 0 100%; /* Cada item ocupa 100% da largura */
      max-width: 100%;
      display: none; /* Oculta itens inativos */
      transition: transform 0.5s ease-in-out;
    }
  
    .carousel-item.active {
      display: block; /* Mostra apenas o item ativo */
    }
  
    .plataforma-card {
      margin: auto;
    }
  
    .carousel-control-prev,
    .carousel-control-next {
      top: 50%;
      transform: translateY(-50%);
    }
  
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: black;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
  